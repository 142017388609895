import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TiArrowUpThick } from "react-icons/ti";
import ObjectivesMenu from "../components/sidemenu/ObjectivesMenu";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Objectives = () => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(true);

    const data = [
        { name: 'JAN', uv: 0 },
        { name: 'FEB', uv: 8000 },
        { name: 'MAR', uv: 3000 },
        { name: 'APR', uv: 1526 },
        { name: 'MAY', uv: 4589 },
        { name: 'JUN', uv: 2390 },
        { name: 'JUL', uv: 3490 },
        { name: 'AUG', uv: 5000 },
        { name: 'SEP', uv: 1000 },
        { name: 'OCT', uv: 7000 },
        { name: 'NOV', uv: 3584 },
        { name: 'DEC', uv: 2569 },
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <ObjectivesMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Objectives</p>
                </div>

                {/* main content */}
                {showPopup &&
                    <div className="flex items-center justify-center">
                        <div className="w-[30%] border border-[#000000] px-5 py-2 rounded mt-[5rem]">
                            <div className="flex justify-end">
                                <IoClose
                                    onClick={() => setShowPopup(false)}
                                    className="text-[24px] text-[#000000] hover:text-[#f35252] cursor-pointer"
                                />
                            </div>
                            <p className=" text-center text-[18px] font-bold mt-10">
                                Welcome to your Objectives Page! Here you can set
                                targets and make sure you are hitting your milestones!
                            </p>
                            <div className="flex items-center justify-between my-10">
                                <button
                                    onClick={() => setShowPopup(false)}
                                    className="bg-[#0DD315] text-white px-3 py-1 rounded"
                                >
                                    Continue to Page
                                </button>

                                <button
                                    onClick={() => navigate("/objectives/add")}
                                    className="bg-[#000000] text-white px-3 py-1 rounded"
                                >
                                    Add Objectives
                                </button>
                            </div>
                        </div>
                    </div>
                }

                {!showPopup &&
                    <div className="mt-5">
                        <p className="text-[16px]">
                            Welcome to your objectives page! Here you can set targets
                            for your advertisements. This will help your company realize
                            its potential growth and make sure you are hitting your milestones !
                        </p>
                        <div className="grid grid-cols-12 gap-5 mt-5">
                            <div className="col-span-4">
                                <p className="text[18px] font-bold mb-2">Current Objectives</p>
                                <p className="text-[16px] mb-2">1. Increase views by 70%</p>
                                <p className="text-[16px] mb-2">2. Increase clicks by 5%</p>
                                <p className="text-[16px] mb-0">3. Increase impression by 10%</p>
                            </div>
                            <div className="col-span-4">
                                <p className="text[18px] font-bold mb-2">Analytics</p>
                                <div className="text-[16px] mb-2 flex items-center">
                                    <p>1. 30 %</p>
                                    <TiArrowUpThick className="text-[20px] mx-1 mb-[4px] text-[#39f14b]" />
                                    <p>in Views this month</p>
                                </div>
                                <div className="text-[16px] mb-2 flex items-center">
                                    <p>2. 25 %</p>
                                    <TiArrowUpThick className="text-[20px] mx-1 mb-[4px] text-[#39f14b]" />
                                    <p>in Views</p>
                                </div>
                                <div className="text-[16px] flex items-center">
                                    <p>3. 0%</p>
                                    <TiArrowUpThick className="text-[20px] mx-1 mb-[4px] text-[#39f14b]" />
                                    <p>in impressions this month</p>
                                </div>
                            </div>
                            <div className="col-span-4">
                                <p className="text[18px] font-bold mb-2">Set Objectives</p>
                                <div className="border border-[#000000] p-3 w-[50%]">
                                    <p
                                        onClick={() => navigate("/objectives/add")}
                                        className="text-[14px] text-white bg-black py-2 text-center rounded cursor-pointer"
                                    >
                                        Add objectives
                                    </p>

                                    <p
                                        onClick={() => navigate("/objectives/add")}
                                        className="text-[14px] text-white bg-black py-2 text-center rounded cursor-pointer mt-3"
                                    >
                                        Edit objectives
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-12 mt-14">
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart
                                        width={500}
                                        height={200}
                                        data={data}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Line connectNulls type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Objectives