import React, { Fragment } from "react";
import { FaSquarePlus } from "react-icons/fa6";

const StageThree = () => {
    return (
        <Fragment>
            {/* step 8 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-2">
                    <p className="text-[18px] font-bold">Step 8 : Upload Logo </p>
                    <div className="flex items-center justify-center mt-5 border border-[#828282] rounded h-[6rem] w-[11rem]">
                        <FaSquarePlus className="text-[25px] cursor-pointer" />
                    </div>
                </div>
            </div>

            {/* step 9 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-2">
                    <p className="text-[18px] font-bold">Step 9 : Choose Prompt </p>
                </div>
                <div className="col-span-12 flex gap-x-10">
                    <div className="flex flex-col gap-2 mt-5">
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="one" className="cursor-pointer accent-black" />
                            <label htmlFor="one">Auto</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="two" className="cursor-pointer accent-black" />
                            <label htmlFor="two">Contact</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="three" className="cursor-pointer accent-black" />
                            <label htmlFor="three">Visit Site</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="four" className="cursor-pointer accent-black" />
                            <label htmlFor="four">Learn more</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="five" className="cursor-pointer accent-black" />
                            <label htmlFor="five">Book Now</label>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-5">  
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="six" className="cursor-pointer accent-black" />
                            <label htmlFor="six">Apply Now</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="seven" className="cursor-pointer accent-black" />
                            <label htmlFor="seven">Sign up</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="eight" className="cursor-pointer accent-black" />
                            <label htmlFor="eight">Subscribe</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="nine" className="cursor-pointer accent-black" />
                            <label htmlFor="nine">Download</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="ten" className="cursor-pointer accent-black" />
                            <label htmlFor="ten">Shop Now</label>
                        </div>
                    </div>
                </div>
            </div>

            {/* advert so far preview */}
            <div className="absolute right-2 top-[12rem] w-[20rem]">
                <p className="text-[16px] font-bold text-center">Your Ad So Far</p>
                <div className="border border-[#828282] rounded p-2 h-[25rem]">
                    <p className="text-[14px] font-bold">Advert</p>
                    <p className="text-[14px] leading-[14px] mt-2">
                        lorem epsum lorem epsum lorem epsumlorem epsum
                    </p>
                    <div className="bg-[#c5c3c3] w-full h-8 rounded mt-5" />
                    <div className="bg-[#c5c3c3] w-full h-8 rounded my-2" />
                    <p className="text-[14px] leading-[14px] mt-5">
                        lorem epsum lorem epsum lorem epsumlorem epsum
                    </p>
                    <div className="bg-[#c5c3c3] w-[50%] h-8 rounded mt-5" />
                    <div className="bg-[#c5c3c3] w-[50%] h-8 rounded my-2" />
                </div>
            </div>
        </Fragment>
    )
}

export default StageThree