import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import TeamUpMenu from "../../components/sidemenu/TeamUpMenu";
import { IoSearchSharp } from "react-icons/io5";
import { RiAttachment2 } from "react-icons/ri";
import { dummyChatData, messageList } from "../../data";

const TeampUpMessage = () => {
    const navigate = useNavigate();
    const [active, setActive] = useState("business");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <TeamUpMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Your Team - Up messages</p>
                </div>

                {/* Your Team - Up messages */}
                <div className="px-[13rem]">
                    <p className="text-[14px] text-center font-bold pt-5">Messages</p>
                    <div className="grid grid-cols-12 mt-5 h-[3rem] border-t border-l border-r border-[#b1b1b1]">
                        <div
                            onClick={() => setActive("business")}
                            className={`col-span-3 flex items-center justify-center border-r border-[#b1b1b1] cursor-pointer 
                                ${active === "business" ? "bg-[#640D5F] text-white" : ""}`
                            }
                        >
                            <p>Business</p>
                        </div>
                        <div
                            onClick={() => setActive("yourMessages")}
                            className={`col-span-3 flex items-center justify-center border-r border-[#b1b1b1] cursor-pointer 
                                ${active === "yourMessages" ? "bg-[#640D5F] text-white" : ""}`
                            }
                        >
                            <p>Your Messages</p>
                        </div>
                        <div
                            onClick={() => setActive("messageRequests")}
                            className={`col-span-3 flex items-center justify-center border-r border-[#b1b1b1] cursor-pointer 
                                ${active === "messageRequests" ? "bg-[#640D5F] text-white" : ""}`
                            }
                        >
                            <p>Message Requests</p>
                        </div>
                        <div
                            onClick={() => setActive("deleteMessages")}
                            className={`col-span-3 flex items-center justify-center border-[#b1b1b1] cursor-pointer 
                                ${active === "deleteMessages" ? "bg-[#640D5F] text-white" : ""}`
                            }
                        >
                            <p>Delete Messages</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 mt-0 h-[30rem] border border-[#b1b1b1]">
                        <div className="col-span-4 border-r h-[30rem] overflow-y-scroll border-[#b1b1b1]">
                            <div className="border-b border-[#b1b1b1] bg-[#ffffff] z-10 p-2 sticky top-0">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Find Contacts or Conversations..."
                                        className="w-full pl-8 pr-2 py-2 border border-[#b1b1b1] text-[14px] rounded-full focus:outline-none"
                                    />
                                    <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
                                        <IoSearchSharp
                                            className="text-[20px] text-[#000000] hover:text-[#00AB07] cursor-pointer"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* contacts */}
                            {messageList.map((data) => (
                                <div
                                    key={data.id}
                                    className="grid grid-cols-12 items-center p-2 border-b border-[#b1b1b1] cursor-pointer hover:bg-[#f2f2f2]"
                                >
                                    <div className="col-span-2">
                                        <img
                                            alt={data.firstName}
                                            src={data.profileImage}
                                            className="h-[3rem] w-[3rem] object-cover rounded-full"
                                        />
                                    </div>
                                    <div className="col-span-10 relative">
                                        <div className="ml-2 w-full">
                                            <p className="text-[12px] font-bold">{data.firstName} {data.lastName}</p>
                                            <p className="text-[10px] text-[#767676] absolute right-0">
                                                {data.postTimeAgo}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-span-8">
                            <div className="border-b border-[#b1b1b1] py-[11.5px] px-2 text-[12px] font-bold">
                                <div className="flex items-center">
                                    <img
                                        alt="img"
                                        className="h-[2rem] w-[2rem] rounded-full mr-2 object-cover"
                                        src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-[12px] font-bold pr-5">John Smith</p>
                                        <p className="text-[8px] text-[#b1b1b1] pr-5">Thursday 27 June 2023 , 11 hours ago</p>
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-y-scroll flex-grow p-1  h-[23rem]">
                                {dummyChatData.map((chat) => (
                                    <div
                                        key={chat.id}
                                        className={`mb-0 
                                            ${chat.isSender ? "text-right flex flex-col items-end justify-end" :
                                                "text-left flex flex-col items-start justify-start"}`
                                        }
                                    >
                                        <div className={`inline-block p-1 rounded w-fit max-w-[60%]`}>
                                            <div className="flex flex-col">
                                                <p className="text-[12px]">{chat.isSender ? "" : `John Smith`} </p>
                                                <p className="text-[8px] text-[#b1b1b1]">{chat.time}</p>
                                            </div>
                                            <p className={`text-[12px] leading-[15px] p-2 rounded-lg  
                                                ${chat.isSender ? "bg-blue-500 text-white" : "bg-gray-300 text-black"}`}
                                            >
                                                {chat.message}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="border-t border-[#b1b1b1] w-full p-2 flex gap-x-2">
                                <div className="bg-[#4a4a4a] px-3 rounded flex items-center justify-center cursor-pointer">
                                    <RiAttachment2 className="text-[18px] text-white" />
                                </div>
                                <div className="relative w-full">
                                    <input
                                        type="text"
                                        placeholder="Type a message..."
                                        className="w-full py-2 pl-2 pr-[3.8rem] text-[14px] rounded focus:outline-none border border-[#4a4a4a]"
                                    />
                                    <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                                        <button className="bg-[#00AB07] text-[#ffffff] px-2 py-1 text-[13px] rounded">
                                            send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeampUpMessage