import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";
import TierSystemMenu from "../../components/sidemenu/TierSystemMenu";

const TierIncreaseApplication = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <TierSystemMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/tier-system")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Tier System
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Tier Increase Application</p>
                </div>

                {/* main content */}
                <p className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]">
                    Tier Increase Application
                </p>

                <div className="grid grid-cols-12 gap-5 mt-5">
                    <div className="col-span-8">
                        <p className="font-bold text-[18px] mb-4">Personal Details</p>
                        <div className="grid grid-cols-10 items-center mb-4">
                            <div className="col-span-2">
                                <p>Full name :</p>
                            </div>
                            <div className="col-span-5">
                                <input
                                    type="text"
                                    placeholder="Enter your full name"
                                    className="w-full border bg-[#d4d4d4] rounded-md px-2 py-1 focus:outline-none"
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-10 items-center mb-4">
                            <div className="col-span-2">
                                <p>Email :</p>
                            </div>
                            <div className="col-span-5">
                                <input
                                    type="text"
                                    placeholder="Enter your email"
                                    className="w-full border bg-[#d4d4d4] rounded-md px-2 py-1 focus:outline-none"
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-10 items-center mb-4">
                            <div className="col-span-2">
                                <p>Phone Number :</p>
                            </div>
                            <div className="col-span-5">
                                <input
                                    type="text"
                                    placeholder="Enter your phone number"
                                    className="w-full border bg-[#d4d4d4] rounded-md px-2 py-1 focus:outline-none"
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-10 items-center mb-4">
                            <div className="col-span-2">
                                <p>Account Number :</p>
                            </div>
                            <div className="col-span-5">
                                <input
                                    type="text"
                                    placeholder="Enter your account number"
                                    className="w-full border bg-[#d4d4d4] rounded-md px-2 py-1 focus:outline-none"
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-10 items-center mb-4">
                            <div className="col-span-2">
                                <p>Supporting documents :</p>
                            </div>
                            <div className="col-span-5">
                                <div className="w-full bg-[#d4d4d4] flex items-center justify-center cursor-pointer rounded-md py-[5px]">
                                    <MdOutlineFileUpload className="text-[24px]" />
                                </div>
                            </div>
                        </div>
                        <div className="mt-10">
                            <p className="text-[14px] font-bold mb-2">Reason for increase of Tier</p>
                            <div className="grid grid-cols-10">
                                <textarea 
                                    rows={5}
                                    placeholder="Enter your reason here"
                                    className="col-span-7 border bg-[#d4d4d4] rounded-md px-2 py-1 w-full resize-none focus:outline-none"
                                />

                                <div className="col-span-7 mt-5 flex items-center justify-center">
                                    <button className="px-10 py-[6px] text-[16px] rounded bg-[#00AB07] text-[#ffffff]">
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="ml-5">
                            <p className="font-bold text-[18px] mb-4">Business Details</p>
                            <p className="text-[14px] mb-2">Company : Anton’s Cookies</p>
                            <p className="text-[14px] mb-2">Current Tier : 2</p>
                            <p className="text-[14px] mb-2">Increase Tier to  : Tier 3</p>
                            <p className="text-[14px] mb-2">Current Revenue : $500,000</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TierIncreaseApplication