import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import TeamUpMenu from "../../components/sidemenu/TeamUpMenu";
import { PopularTeamUpData } from "../../data";
import FilterSearch from "./FilterSearch";
import { IoSearchSharp } from "react-icons/io5";

const PopularTeamUp = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <TeamUpMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Popular Team - Up Advertisers</p>
                </div>

                {/* main content */}
                <p className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]">
                    Popular Team - Up Advertisers
                </p>
                <div className="grid grid-cols-12 gap-5 mt-10">
                    <div className="col-span-9">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search for Team-Up"
                                onClick={() => navigate("/team-up/search")}
                                className="bg-[#ffffff] pl-8 pr-2 py-2 w-[50%] border border-black text-[13px] rounded focus:outline-none"
                            />
                            <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
                                <IoSearchSharp
                                    className="text-[20px] text-[#000000] hover:text-[#f65d5d] cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 flex justify-end">
                        <FilterSearch />
                    </div>
                    {PopularTeamUpData.map((data) => (
                        <div className="col-span-3 border border-black" key={data.id}>
                            <p className="text-[18px] font-bold border-b border-black p-3">
                                {data.name}
                            </p>
                            <div className="p-3">
                                <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                <div className="bg-[#D9D9D9] p-3 overflow-y-scroll h-[8rem] mt-1">
                                    <p className="text-[14px]">
                                        {data.about}
                                    </p>
                                </div>
                                <div className="flex items-center gap-x-4 mt-2">
                                    <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                    <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                </div>
                            </div>
                            <div className="flex items-center justify-center mt-2 mb-8">
                                <p
                                    onClick={() => navigate(`/team-up/application/${data.id}`)}
                                    className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] px-8 py-2 rounded-md cursor-pointer"
                                >
                                    Team - UP
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PopularTeamUp