import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import AdvertMenu from "../../components/sidemenu/AdvertMenu";
import { yourAdvertData } from "../../data";
import { IoIosCloseCircle } from "react-icons/io";

const DraftedAdvert = () => {
    const navigate = useNavigate();
    const [advertType, setAdvertType] = useState("");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <AdvertMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/your-advert")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Your Adverts
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Drafted Adverts</p>
                </div>

                {/* main content */}
                <div className="grid grid-cols-12">
                    <div className="col-span-4" />
                    <p className="col-span-4 text-[20px] font-bold text-center mt-5 underline underline-offset-[5px]">
                        Drafted Adverts
                    </p>
                    <div className="col-span-4 flex items-center justify-end gap-x-3">
                        <p className="text-[#626262] text-[14px] font-bold">Advert Type :</p>
                        <div className="relative">
                            <select
                                required
                                value={advertType}
                                onChange={(e) => setAdvertType(e.target.value)}
                                className="w-[10rem] bg-[#000000] px-3 py-[6px] text-white text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Select</option>
                                <option value="Stacic Advert">Stacic Advert</option>
                                <option value="Video Advert">Video Advert</option>
                                <option value="Pop-Up Advert">Pop-Up Advert</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="mt-10">
                    <p className="text-[20px] font-bold underline underline-offset-[5px]">
                        Showing : Static Kind Viewer Advert  Drafts
                    </p>
                    <div className="grid grid-cols-12 gap-5 mt-10">
                        {yourAdvertData.map((data) => (
                            <div className="col-span-2" key={data.id}>
                                <div className="flex items-center gap-x-3 justify-center">
                                    <p className="text-[14px] text-[#626262]">
                                        Created : {data.created}
                                    </p>
                                    <IoIosCloseCircle
                                        className="text-[20px] text-[#000000] hover:text-[#FF0000] cursor-pointer"
                                    />
                                </div>
                                <img
                                    alt="advert"
                                    src={data.image}
                                    className="w-full h-[10rem] object-cover mt-2"
                                />
                                <p className="p-2 mt-2 bg-[#d9d9d9] text-center text-[14px] font-bold line-clamp-1">
                                    {data.name} Advert
                                </p>
                                <div className="flex items-center justify-between mt-2">
                                    <button className="text-[12px] w-[6rem] text-[#ffffff] rounded py-2 bg-[#00AB07]">
                                        Edit
                                    </button>
                                    <button 
                                        onClick={() => navigate(`/your-advert/post-view/${data.id}`)}
                                        className="text-[12px] w-[6rem] text-[#ffffff] rounded py-2 bg-[#640D5F]"
                                    >
                                        Bid and Post
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DraftedAdvert