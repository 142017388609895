import React, { useState } from "react";
import { MdFilterAlt } from "react-icons/md";

const FilterSearch = () => {
    const [showFilter, setShowFilter] = useState(false);
    const [bidCategory, setBidCategory] = useState("");
    const [milesCategory, setMilesCategory] = useState("");

    return (
        <div className="relative">
            <div
                onClick={() => setShowFilter(!showFilter)}
                className="flex items-center justify-center gap-x-2 bg-black w-[12rem] py-2 cursor-pointer"
            >
                <p className="text-[14px] text-[#ffffff]">
                    Filter Search
                </p>
                <MdFilterAlt className="text-[18px] text-[#f68c36]" />
            </div>
            {showFilter &&
                <div className="absolute right-0 w-full bg-[#D9D9D9] border border-black p-2 z-10">
                    <div className="flex items-start gap-x-3">
                        <input
                            type="checkbox"
                            className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                        />
                        <div className="flex flex-col mt-[-2px]">
                            <p className="text-[12px]">Location</p>
                            <div className="relative">
                                <select
                                    required
                                    value={bidCategory}
                                    onChange={(e) => setBidCategory(e.target.value)}
                                    className="w-[9rem] bg-[#ffffff] px-3 py-[2px] text-black text-[12px] border border-[#ffffff] focus:outline-none appearance-none"
                                >
                                    <option value={""}>Select</option>
                                    <option value="50 miles">50 miles</option>
                                    <option value="100 miles">100 miles</option>
                                    <option value="200 miles">200 miles</option>
                                </select>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-3 w-3 ml-1 absolute top-2 right-2.5 text-slate-700 text-black">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-start gap-x-3 mt-2">
                        <input
                            type="checkbox"
                            className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                        />
                        <div className="flex flex-col mt-[-2px]">
                            <p className="text-[12px]">Tier</p>
                            <div className="relative">
                                <select
                                    required
                                    value={milesCategory}
                                    onChange={(e) => setMilesCategory(e.target.value)}
                                    className="w-[9rem] bg-[#ffffff] px-3 py-[2px] text-black text-[12px] border border-[#ffffff] focus:outline-none appearance-none"
                                >
                                    <option value={""}>Select</option>
                                    <option value="Only tier 1">Only tier 1</option>
                                    <option value="Only tier 2">Only tier 2</option>
                                    <option value="Only tier 3">Only tier 3</option>
                                </select>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-3 w-3 ml-1 absolute top-2 right-2.5 text-slate-700 text-black">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-start gap-x-3 mt-2">
                        <input
                            type="checkbox"
                            className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                        />
                        <div className="flex flex-col mt-[-2px]">
                            <p className="text-[12px]">Keywords</p>
                            <input 
                                type="text"
                                className="bg-[#ffffff] px-1 py-[2px] w-[9rem] mt-[2px] text-[13px] focus:outline-none"
                            />
                        </div>
                    </div>

                    <div className="flex items-start gap-x-3 mt-2">
                        <input
                            type="checkbox"
                            className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                        />
                        <div className="flex flex-col mt-[-2px]">
                            <p className="text-[12px]">Business Name</p>
                            <input 
                                type="text"
                                className="bg-[#ffffff] px-1 py-[2px] w-[9rem] mt-[2px] text-[13px] focus:outline-none"
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default FilterSearch