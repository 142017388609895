import React from "react";
import { GoHomeFill } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { oneCentBidData } from "../data";
import AdvertMenu from "../components/sidemenu/AdvertMenu";

const YourAdvert = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <AdvertMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/your-advert")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Your Adverts
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Active Adverts</p>
                </div>

                {/* main content */}
                <div className="flex justify-between mt-5">
                    <p className="text-[20px] font-bold">
                        Active Ads On Display
                    </p>
                    <div className="flex flex-col bg-[#d9d9d9] p-3 rounded">
                        <p className="font-bold text-[14px] mb-2">Business : Anton’s Cookies</p>
                        <p className="font-bold text-[14px]">Tier : Tier 3</p>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-5 mt-10">
                    {oneCentBidData.map((data) => (
                        <div
                            key={data.id}
                            className="col-span-2"
                            onClick={() => navigate(`/your-advert/post-view/${data.id}`)}
                        >
                            <p className="text-center bg-[#d9d9d9] text-[14px] font-bold py-1">
                                {data.name} Ad
                            </p>
                            <img
                                alt="advert"
                                src={data.image}
                                onClick={() => navigate(`/your-advert/post-view/${data.id}`)}
                                className="w-full h-[8rem] object-cover mt-1"
                            />
                            <div className="mt-2 border border-[#c3c3c3] p-3 text-[14px]">
                                <div className="flex items-center justify-between mb-1">
                                    <p>CP3V :</p>
                                    <p>Tier : 1</p>
                                </div>
                                <p className="mb-1">CP3C :</p>
                                <p className="mb-1">CP3000 Impressions :</p>
                                <p>Expenditure :</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default YourAdvert