import React from "react";
import { useNavigate } from "react-router-dom";
import objectives from "../../assets/objectives/objectives.png";
import your_timeline from "../../assets/objectives/your_timeline.png";
import your_ads from "../../assets/objectives/your_adverts.png";
import one_cent_bidding from "../../assets/objectives/1_cent_bidding.png";
import team_up from "../../assets/objectives/team_up.png";
import financials from "../../assets/objectives/financials.png";

const ObjectivesMenu = () => {
    const navigate = useNavigate();

    const menuItem = [
        { id: 1, name: "Objectives", icon: objectives, pathName: "/objectives" },
        { id: 2, name: "Your Timeline", icon: your_timeline, pathName: "/objectives/your-timeline" },
        { id: 3, name: "Your Ads", icon: your_ads, pathName: "/your-advert" },
        { id: 4, name: "1 - Cent Bidding", icon: one_cent_bidding, pathName: "/one-cent" },
        { id: 5, name: "Team - Up", icon: team_up, pathName: "/team-up" },
        { id: 6, name: "Financials", icon: financials, pathName: "/financial" },
        { id: 7, name: "Total users reached", number: "123000", pathName: "" }
    ];

    return (
        <div className="flex flex-col items-center mt-3">
            {menuItem.map((item) => (
                <div
                    key={item.id}
                    onClick={() => navigate(item.pathName)}
                    className="h-[6rem] w-[6rem] p-5 flex flex-col items-center justify-center rounded-full bg-[#ffffff] mb-3 cursor-pointer"
                >
                    {item.icon ? (
                        <img
                            src={item.icon}
                            alt={item.name}
                            className="h-[2.5rem] w-[2.5rem] mb-1"
                        />
                    ) : (
                        <p className="text-[18px] font-bold">{item.number}</p>
                    )}
                    <p className="text-[10px] text-[#000000] font-semibold leading-[12px] text-center">
                        {item.name}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default ObjectivesMenu