import React from "react";
import create_adverts from "../../assets/youradvert/create_adverts.png";
import active_adverts from "../../assets/youradvert/active_adverts.png";
import kv_adverts from "../../assets/youradvert/kv.png";
import inactive_adverts from "../../assets/youradvert/Inactive_adverts.png";
import drafted_adverts from "../../assets/youradvert/drafted_adverts.png";
import quick_create from "../../assets/youradvert/quick_create.png";
import { useNavigate } from "react-router-dom";

const AdvertMenu = () => {
    const navigate = useNavigate();

    const menuItem = [
        { id: 1, name: "Create Adverts", icon: create_adverts, pathName: "/create-advert" },
        { id: 2, name: "Active adverts", icon: active_adverts, pathName: "/your-advert" },
        { id: 3, name: "KV Adverts", icon: kv_adverts, pathName: "" },
        { id: 4, name: "Inactive Adverts", icon: inactive_adverts, pathName: "/your-advert/inactive" },
        { id: 5, name: "Drafted Adverts", icon: drafted_adverts, pathName: "/your-advert/draft" },
        { id: 6, name: "Quick Create", icon: quick_create, pathName: "/quick-create" },
        { id: 7, name: "Total interactions", number: "123000", pathName: "" },
    ];

    return (
        <div className="flex flex-col items-center mt-3">
            {menuItem.map((item) => (
                <div
                    key={item.id}
                    onClick={() => navigate(item.pathName)}
                    className="h-[6rem] w-[6rem] p-3 flex flex-col items-center justify-center rounded-full bg-[#ffffff] mb-3 cursor-pointer"
                >
                    {item.icon ? (
                        <img
                            src={item.icon}
                            alt={item.name}
                            className="h-[2.5rem] w-[2.5rem] mb-1"
                        />
                    ) : (
                        <p className="text-[18px] font-bold">{item.number}</p>
                    )}
                    <p className="text-[10px] text-[#000000] font-semibold leading-[12px] text-center">
                        {item.name}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default AdvertMenu