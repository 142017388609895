import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdFilterAlt, MdKeyboardArrowRight } from "react-icons/md";
import TierSystemMenu from "../../components/sidemenu/TierSystemMenu";
import { IoSearchSharp } from "react-icons/io5";
import { yourLocalAdvertisersData } from "../../data";

const HigherTierAdvertiser = () => {
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <TierSystemMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/tier-system")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Tier System
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Higher Tier Advertisers Team - Up</p>
                </div>

                {/* main content */}
                <p className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]">
                    Higher Tier Advertisers Team - Up
                </p>

                <div className="grid grid-cols-12 mt-10">
                    <div className="col-span-4">
                        <div className="flex items-center gap-x-3">
                            <p className="text-[16px] font-bold">Your Tier :</p>
                            <p className="text-[14px] flex item center bg-[#D9D9D9] text-[#626262] px-2 py-1">Tier 3</p>
                        </div>
                        <div className="flex flex-col mt-5">
                            <p className="text-[16px] font-bold">Perks :</p>
                            <p className="text-[14px] flex item center bg-[#D9D9D9] text-[#000000] w-[60%] p-5 mt-2">
                                The perks of Tier 3 include being third in the rolling advertisements.
                                You also bid against other advertisers in Tier 3 with similar revenues
                            </p>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Enter the name of the advertiser"
                                className="w-full pl-8 pr-2 py-2 border border-[#b1b1b1] text-[14px] rounded-full focus:outline-none"
                            />
                            <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
                                <IoSearchSharp
                                    className="text-[20px] text-[#000000] hover:text-[#00AB07] cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 flex justify-end">
                        <div className="relative">
                            <div
                                onClick={() => setShowFilter(!showFilter)}
                                className="flex items-center justify-center gap-x-2 bg-black w-[8rem] py-2 cursor-pointer"
                            >
                                <p className="text-[14px] text-[#ffffff]">
                                    Filter Search
                                </p>
                                <MdFilterAlt className="text-[18px] text-[#f68c36]" />
                            </div>

                            {showFilter &&
                                <div className="absolute right-0 w-full bg-[#D9D9D9] border border-black p-2 z-10">
                                    <div className="flex items-center gap-x-3 mb-1">
                                        <input
                                            type="checkbox"
                                            className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                                        />
                                        <p className="text-[12px]">Tier 4</p>
                                    </div>
                                    <div className="flex items-center gap-x-3 mb-1">
                                        <input
                                            type="checkbox"
                                            className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                                        />
                                        <p className="text-[12px]">Tier 3</p>
                                    </div>
                                    <div className="flex items-center gap-x-3 mb-1">
                                        <input
                                            type="checkbox"
                                            className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                                        />
                                        <p className="text-[12px]">Tier 2</p>
                                    </div>
                                    <div className="flex items-center gap-x-3">
                                        <input
                                            type="checkbox"
                                            className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                                        />
                                        <p className="text-[12px]">Tier 1</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-5 mt-10">
                    {yourLocalAdvertisersData.map((data) => (
                        <div className="col-span-3 border border-black" key={data.id}>
                            <p className="text-[18px] font-bold border-b border-black p-3">
                                {data.name}
                            </p>
                            <div className="p-3">
                                <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                <div className="bg-[#D9D9D9] p-3 overflow-y-scroll h-[8rem] mt-1">
                                    <p className="text-[14px]">
                                        {data.about}
                                    </p>
                                </div>
                                <div className="flex items-center gap-x-4 mt-2">
                                    <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                    <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                </div>
                            </div>
                            <div className="flex items-center justify-center mt-2 mb-8">
                                <p
                                    onClick={() => navigate(`/team-up/application/view/${data.id}`)}
                                    className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] px-8 py-2 rounded-md cursor-pointer"
                                >
                                    Team - UP
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HigherTierAdvertiser