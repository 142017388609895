import React, { Fragment, useState } from "react";
import congrats_img from "../../../assets/createadvert/congrats_img.png"

const StageFour = () => {
    const [location, setLocation] = useState("");
    const [language, setLanguage] = useState("");

    return (
        <Fragment>
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-6">
                    <p className="text-[16px] font-bold">Targeting : Choose Location</p>
                    <div className="relative w-[75%] mt-3">
                        <select
                            required
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className="w-full bg-[#d9d9d9] px-3 py-[10px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                        >
                            <option value={""}>Select</option>
                            <option value="United States of America (USA)">United States of America (USA)</option>
                            <option value="United Kingdom (london)">United Kingdom (london)</option>
                            <option value="Dubai">Dubai</option>
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-3.5 right-2.5 text-slate-700 text-black">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                    </div>
                </div>
                <div className="col-span-6">
                    <p className="text-[16px] font-bold">Targeting : Choose Location</p>
                    <div className="relative w-[75%] mt-3">
                        <select
                            required
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            className="w-full bg-[#d9d9d9] px-3 py-[10px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                        >
                            <option value={""}>Select</option>
                            <option value="English">English</option>
                            <option value="Spanish">Spanish</option>
                            <option value="French">French</option>
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-3.5 right-2.5 text-slate-700 text-black">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-6">
                    <p className="text-[18px] font-bold mb-2">Advert Preview</p>
                    <div className="border border-[#828282] rounded p-2 h-[25rem] w-[20rem] overflow-y-scroll">
                        <p className="text-[14px] font-bold">Advert</p>
                        <p className="text-[14px] leading-[14px] mt-2">
                            lorem epsum lorem epsum lorem epsumlorem epsum
                        </p>
                        <div className="bg-[#c5c3c3] w-full h-8 rounded mt-5" />
                        <div className="bg-[#c5c3c3] w-full h-8 rounded my-2" />
                        <p className="text-[14px] leading-[14px] mt-5">
                            lorem epsum lorem epsum lorem epsumlorem epsum
                        </p>
                        <img
                            alt="advert"
                            className="w-full h-[10rem] mt-5 rounded"
                            src="https://cdn.pixabay.com/photo/2021/03/12/17/54/coca-cola-6090176_1280.jpg"
                        />
                    </div>
                </div>
                <div className="col-span-6">
                    <div className="w-[30rem] mt-10">
                        <p className="text-[28px] px-10 font-bold text-[#00AB07] text-center">
                            Congratulations! Your advertisement: Vanilla Cookie Sale has been created!
                        </p>
                        <p className="text-[14px] text-[#00C2FF] mt-5 text-center">
                            Continue to 1-Cent Bidding to Bid on your Ad
                        </p>
                        <div className="flex items-center justify-center">
                            <img 
                                alt="congrats" 
                                src={congrats_img} 
                                className="h-[16rem] mx-auto mt-5"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default StageFour