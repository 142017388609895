import React from "react"
import { dummyChatData } from "../../data"

const MessagePopup = () => {
    return (
        <div className="border border-black w-[70%] h-[25rem] flex flex-col justify-between rounded">
            <div className="overflow-y-auto flex-grow p-1">
                {dummyChatData.map((chat) => (
                    <div
                        key={chat.id}
                        className={`mb-0 
                            ${chat.isSender ? 'text-right flex flex-col items-end justify-end' :
                                'text-left flex flex-col items-start justify-start'}`
                        }
                    >
                        <div className={`inline-block p-1 rounded w-[65%]`}>
                            <div className="flex items-center justify-between gap-x-3">
                                <p className="text-[12px]">{chat.name}</p>
                                <p className="text-[8px]">{chat.time}</p>
                            </div>
                            <p className={`text-[12px] leading-[15px] p-2 rounded-lg  
                                ${chat.isSender ? "bg-blue-500 text-white" : "bg-gray-300 text-black"}`}
                            >
                                {chat.message}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="border-t border-black p-2">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Type a message..."
                        className="w-full py-2 pl-2 pr-[3.8rem] text-[14px] rounded focus:outline-none border border-black"
                    />
                    <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                        <button className="bg-[#00AB07] text-[#ffffff] px-2 py-1 text-[13px] rounded">
                            send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessagePopup