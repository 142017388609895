import React from "react"
import MainMenu from "../components/sidemenu/MainMenu"
import { useNavigate } from "react-router-dom"

const Login = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <MainMenu />
            </div>
            <div className="col-span-11 mt-[2rem]">
                <p className="flex justify-center text-[20px] font-semibold">
                    Kind Viewer Ad Share Log - In
                </p>
                <div className="flex justify-center mt-[2rem]">
                    <div className="w-[30rem] border border-[#000000] bg-[#ffffff] rounded-lg p-5">
                        <div className="flex flex-col gap-y-3">
                            <div className="grid grid-cols-12 gap-4 items-center">
                                <div className="col-span-3">
                                    <p className="text-[16px]">
                                        Email:
                                    </p>
                                </div>
                                <div className="col-span-9">
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        className="w-full px-3 py-2 border border-[#000000] rounded"
                                    />
                                </div>
                                <div className="col-span-3">
                                    <p className="text-[16px]">
                                        Password:
                                    </p>
                                </div>
                                <div className="col-span-9">
                                    <input
                                        type="password"
                                        placeholder="password"
                                        className="w-full px-3 py-2 border border-[#000000] rounded"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-between items-center pt-1 pb-5">
                                <div className="flex items-center gap-x-2">
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 cursor-pointer accent-[#00AB07]"
                                    />
                                    <p className="text-[14px]">
                                        Keep me signed in
                                    </p>
                                </div>
                                <p className="text-[14px] text-[#00C2FF] cursor-pointer hover:font-bold">
                                    Forgot Password
                                </p>
                            </div>
                            <div className="flex justify-between items-center">
                                <button
                                    onClick={() => navigate("/signup")}
                                    className="w-[8rem] py-2 text-[14px] bg-[#00AB07] text-[#ffffff] rounded"
                                >
                                    Signup
                                </button>
                                <button
                                    onClick={() => navigate("/home")}
                                    className="w-[8rem] py-2 text-[14px] bg-[#000000] text-[#ffffff] rounded"
                                >
                                    Log In
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login