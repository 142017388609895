import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import MainMenu from "../../components/sidemenu/MainMenu";
import { TbArrowBigRightFilled } from "react-icons/tb";

const WorkSteps = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <MainMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate(-1)} className="cursor-pointer">How it works</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">
                        Create Account and Set business
                    </p>
                </div>

                <div className="mt-5">
                    <p className="text-[18px] font-bold mb-2">Step 1</p>
                    <div className="border border-black rounded p-5">
                        <p className="text-[18px] font-bold mb-2">Explanation</p>
                        <p className="text-[14px]">
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum." "Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt " sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum." "Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt "
                        </p>

                        <div className="flex flex-col items-center justify-center mt-10">
                            <p className="text-[12px] text-[#393939]">Did this answer your question?</p>
                            <div className="flex items-center gap-x-4">
                                <p className="cursor-pointer">😊</p>
                                <p className="cursor-pointer">😒</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-12 bg-[#6b6b6b] mt-5 h-[10rem] rounded">
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-[24px] text-center text-white font-bold p-5">
                            Create Account and <br />
                            Set Business
                        </p>
                    </div>
                    <div className="col-span-4 flex items-center justify-center">
                        <TbArrowBigRightFilled
                            className="text-[6rem] text-[#ffffff] cursor-pointer"
                        />
                    </div>
                </div>

                <div className="flex items-center justify-end mt-5">
                    <button className="text-[14px] bg-[#00AB07] text-[#ffffff] px-5 py-2 rounded">
                        Continue to Step 2
                    </button>
                </div>
            </div>
        </div>
    )
}

export default WorkSteps