import React from "react";
import { useNavigate } from "react-router-dom";
import team_up from "../../assets/sidemenuimg/team_up.png";
import financials from "../../assets/sidemenuimg/financials.png";
import objectives from "../../assets/sidemenuimg/objectives.png";
import performance from "../../assets/sidemenuimg/performance.png";
import tier_system from "../../assets/sidemenuimg/tier_system.png";
import your_adverts from "../../assets/sidemenuimg/your_adverts.png";
import quick_create from "../../assets/sidemenuimg/quick_create.png";
import cent_bidding from "../../assets/sidemenuimg/1_cent_bidding.png";

const TeamUpMenu = () => {
    const navigate = useNavigate();

    const menuItem = [
        { id: 1, name: "Team - Up Search", icon: cent_bidding, pathName: "/team-up/search" },
        { id: 2, name: "Total users reached", number: "123000", pathName: "" },
        { id: 3, name: "Your Current Team - Ups", icon: your_adverts, pathName: "/team-up/current" },
        { id: 4, name: "Popular Team - Up Advertisers", icon: tier_system, pathName: "/team-up/popular" },
        { id: 5, name: "Team - Up Filters", icon: team_up, pathName: "" },
        { id: 6, name: "Your Team-Up Applications", icon: quick_create, pathName: "/team-up/application" },
        { id: 7, name: "Team - Up Profile", icon: performance, pathName: "/team-up/profile" },
        { id: 8, name: "Team - Up Messages", icon: financials, pathName: "/team-up/message" },
        { id: 9, name: "How Team - Up works", icon: objectives, pathName: "" }
    ];

    return (
        <div className="flex flex-col items-center mt-3">
            {menuItem.map((item) => (
                <div
                    key={item.id}
                    onClick={() => navigate(item.pathName)}
                    className="h-[6rem] w-[6rem] p-3 flex flex-col items-center justify-center rounded-full bg-[#ffffff] mb-3 cursor-pointer"
                >
                    {item.icon ? (
                        <img
                            src={item.icon}
                            alt={item.name}
                            className="h-[2.5rem] w-[2.5rem] mb-1"
                        />
                    ) : (
                        <p className="text-[18px] font-bold">{item.number}</p>
                    )}
                    <p className="text-[10px] text-[#000000] font-semibold leading-[12px] text-center">
                        {item.name}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default TeamUpMenu