import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import TierSystemMenu from "../components/sidemenu/TierSystemMenu";
import { yourLocalAdvertisersData } from "../data";

const TierSystem = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <TierSystemMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Your Tier</p>
                </div>

                {/* main content */}
                <div className="flex items-center gap-x-3 mt-5">
                    <p className="text-[16px] font-bold">Your Tier : </p>
                    <p className="text-[14px] flex item center bg-[#D9D9D9] text-[#626262] px-2 py-1">Tier 3</p>
                </div>

                <div className="border border-black rounded p-2 mt-5">
                    <p className="text-[16px] font-bold">
                        Perks of your tier :
                    </p>
                    <p className="text-[14px] text-[#626262] mt-3">
                        Your advert will be played third in line based on a rolling play system.
                        This is based on your company economic scale and revenue. Your advert will
                        be played third in line based on a rolling play system. This is based on
                        your company economic scale and revenue. Your advert will be played third
                        in line based on a rolling play system. This is based on your company economic
                        scale and revenue. Your advert will be played third in line based on a rolling
                        play system. This is based on your company economic scale and revenue.
                    </p>
                </div>

                <div className="mt-10">
                    <div className="flex items-center justify-between">
                        <p className="px-4 py-[5px] bg-[#D9D9D9] rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit">
                            Similar Advertisers
                        </p>

                        <p 
                            onClick={() => navigate("/tier-system/similar-advertiser")}
                            className="px-4 py-[5px] bg-[#000000] text-white rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit"
                        >
                            view all
                        </p>
                    </div>

                    <div className="grid grid-cols-12 gap-5 mt-3">
                        {yourLocalAdvertisersData.map((data) => (
                            <div className="col-span-3 border border-black" key={data.id}>
                                <p className="text-[18px] font-bold border-b border-black p-3">
                                    {data.name}
                                </p>
                                <div className="p-3">
                                    <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                    <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                    <div className="bg-[#D9D9D9] p-3 overflow-y-scroll h-[8rem] mt-1">
                                        <p className="text-[14px]">
                                            {data.about}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-4 mt-2">
                                        <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                        <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center mt-2 mb-8">
                                    <p
                                        onClick={() => navigate(`/team-up/application/view/${data.id}`)}
                                        className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] px-8 py-2 rounded-md cursor-pointer"
                                    >
                                        Team - UP
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TierSystem