import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { FaCircleChevronDown } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import MainMenu from "../components/sidemenu/MainMenu";
import { IoSearchSharp, IoClose } from "react-icons/io5";

const YourKeywords = () => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState("");

    const Keywords = [
        { id: 1, name: "Cookies" },
        { id: 2, name: "Snacks" },
        { id: 3, name: "Tasty" },
        { id: 4, name: "Chocolate chip" },
        { id: 5, name: "Vanilla ice cream" },
        { id: 6, name: "Anton’s" },
        { id: 7, name: "Crunchy" }
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <MainMenu />
            </div>
            <div className="col-span-11 p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Your Keywords</p>
                </div>

                {/* content */}
                <div className="grid grid-cols-12 gap-2 mt-5">
                    <div className="col-span-12">
                        <p className="text-[16px] font-bold">
                            Your Keywords
                        </p>
                    </div>
                    <div className="col-span-3 border border-black rounded p-3">
                        {Keywords.map((item) => (
                            <div className="grid grid-cols-12 mb-1 items-center" key={item.id}>
                                <div className="col-span-10">
                                    {item.name}
                                </div>
                                <div className="col-span-2 flex items-center justify-end">
                                    <IoClose className="text-[20px] cursor-pointer hover:text-red-500" />
                                </div>
                            </div>
                        ))}
                        <div className="flex items-center justify-center w-full mt-2">
                            <FaCircleChevronDown
                                className="text-[24px] cursor-pointer hover:text-[#00AB07]"
                            />
                        </div>
                    </div>
                    <div className="col-span-9">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search Keywords...."
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                className="bg-[#ffffff] pl-2 pr-8 py-2 w-full border border-black text-[13px] rounded focus:outline-none"
                            />
                            <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                                {inputValue.length > 1 ? (
                                    <IoClose
                                        className="text-[20px] text-[#000000] hover:text-[#f65d5d] cursor-pointer"
                                        onClick={() => setInputValue("")}
                                    />
                                ) : (
                                    <IoSearchSharp className="text-[20px] text-[#000000] cursor-pointer" />
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-2 mt-2">
                            <div className="col-span-6">
                                <p className="text-[14px] text-center font-bold">Your Niche Keywords</p>
                                <div className="border border-black rounded p-3 h-[15rem] overflow-y-scroll">
                                    <p className="text-[14px] hover:text-[#00AB07]">1. Anton’s</p>
                                    <p className="text-[14px] hover:text-[#00AB07]">2. Crunchy</p>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <p className="text-[14px] text-center font-bold">Your Common Keywords</p>
                                <div className="border border-black rounded p-3 h-[15rem] overflow-y-scroll">
                                    <p className="text-[14px] hover:text-[#00AB07]">1. Tasty</p>
                                    <p className="text-[14px] hover:text-[#00AB07]">2. Cookies</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-5 border border-black p-3 rounded">
                    <p className="text-[20px] mb-2 font-bold">What are Keywords ?</p>
                    <p className="text-[14px]">
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                        mollit anim id est laborum."
                    </p>
                </div>
            </div>
        </div>
    )
}

export default YourKeywords