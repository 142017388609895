import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { businessSpecificData } from "../../data";
import { GoHomeFill } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import TeamUpMenu from "../../components/sidemenu/TeamUpMenu";

const TeamUpApplication = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const teamUpDetails = businessSpecificData.find(teamUp => teamUp.id === parseInt(id));
    const [applyingAs, setApplyingAs] = useState("");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <TeamUpMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Team Up Application: {teamUpDetails.name}</p>
                </div>

                {/* application details */}
                <p className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]">
                    Application To Team - Up with {teamUpDetails.name}
                </p>
                <div className="grid grid-cols-12 gap-5 mt-10">
                    <div className="col-span-4 flex items-center gap-x-4">
                        <p className="text-[14px] font-bold">Applying as :</p>
                        <div className="relative">
                            <select
                                required
                                value={applyingAs}
                                onChange={(e) => setApplyingAs(e.target.value)}
                                className="w-[18rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Select</option>
                                <option value="Anton’s Cookies">Anton’s Cookies</option>
                                <option value="Mike’s Baked Goods">Mike’s Baked Goods</option>
                                <option value="Bobs Cookie Shop">Bobs Cookie Shop</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                    <div className="col-span-4 flex items-center justify-center gap-x-4">
                        <p className="text-[14px] font-bold">Email :</p>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="w-[18rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none rounded"
                        />
                    </div>
                    <div className="col-span-4 flex items-center justify-end gap-x-4">
                        <p className="text-[14px] font-bold">Phone :</p>
                        <input
                            type="number"
                            placeholder="Enter your phone number"
                            className="w-[18rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none rounded"
                        />
                    </div>
                    <div className="col-span-6">
                        <p className="text-[14px] font-bold">Provide a general description of your business :</p>
                        <textarea
                            rows={8}
                            placeholder="Enter your business description"
                            className="w-full bg-[#d9d9d9] px-3 py-[6px] mt-1 text-black text-[14px] border border-[#ffffff] focus:outline-none resize-none rounded"
                        />
                    </div>
                    <div className="col-span-6">
                        <p className="text-[14px] font-bold">Provide a general description of proposed Team - Up :</p>
                        <textarea
                            rows={8}
                            placeholder="Enter your business description"
                            className="w-full bg-[#d9d9d9] px-3 py-[6px] mt-1 text-black text-[14px] border border-[#ffffff] focus:outline-none resize-none rounded"
                        />
                    </div>
                    <div className="col-span-12 flex justify-center">
                        <button
                            onClick={() => navigate("/team-up/application/submit")}
                            className="text-[14px] py-2 w-[15rem] bg-[#00AB07] text-[#ffffff] rounded"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamUpApplication