import React, { Fragment } from "react";
import { FaSquarePlus } from "react-icons/fa6";

const StageTwo = () => {
    return (
        <Fragment>
            {/* step 5 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-2">
                    <p className="text-[18px] font-bold">Step 5 : Header/Title</p>
                    <p className="text-[12px] mt-10">Type your Header/Title here :</p>
                </div>
                <div className="col-span-5">
                    <textarea
                        rows={4}
                        placeholder="Type in your header/title..."
                        className="border w-full border-[#828282] p-2 mt-5 rounded focus:outline-none resize-none"
                    />
                </div>
            </div>

            {/* step 6 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-7">
                    <p className="text-[18px] font-bold">Step 6 : Product Explanation</p>
                    <div className="flex items-center gap-x-3 mt-5">
                        <p className="text-[18px] font-bold">1</p>
                        <input
                            type="text"
                            className="border w-full border-[#828282] p-2 rounded focus:outline-none"
                        />
                    </div>
                    <div className="flex items-center gap-x-3 mt-2">
                        <p className="text-[18px] font-bold">2</p>
                        <input
                            type="text"
                            className="border w-full border-[#828282] p-2 rounded focus:outline-none"
                        />
                    </div>
                    <div className="flex items-center gap-x-3 mt-2">
                        <p className="text-[18px] font-bold">3</p>
                        <input
                            type="text"
                            className="border w-full border-[#828282] p-2 rounded focus:outline-none"
                        />
                    </div>
                </div>
            </div>

            {/* step 7 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-2">
                    <p className="text-[18px] font-bold">Step 7 : Upload media</p>
                    <div className="flex items-center justify-center mt-5 border border-[#828282] rounded h-[6rem] w-[11rem]">
                        <FaSquarePlus className="text-[25px] cursor-pointer" />
                    </div>
                </div>
            </div>

            {/* advert so far preview */}
            <div className="absolute right-2 top-[12rem] w-[20rem]">
                <p className="text-[16px] font-bold text-center">Your Ad So Far</p>
                <div className="border border-[#828282] rounded p-2 h-[25rem]">
                    <p className="text-[14px] font-bold">Advert</p>
                    <p className="text-[14px] leading-[14px] mt-2">
                        lorem epsum lorem epsum lorem epsumlorem epsum
                    </p>
                    <div className="bg-[#c5c3c3] w-full h-8 rounded mt-5" />
                    <div className="bg-[#c5c3c3] w-full h-8 rounded my-2" />
                    <p className="text-[14px] leading-[14px] mt-5">
                        lorem epsum lorem epsum lorem epsumlorem epsum
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

export default StageTwo