import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import MainMenu from "../components/sidemenu/MainMenu";

const SignUp = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    return (
        <div className="grid grid-cols-12 h-screen">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <MainMenu />
            </div>
            <div className="col-span-11 mt-[1rem]">
                {(step === 2 || step === 3) &&
                    <div className="px-3">
                        <FaArrowLeftLong
                            onClick={() => setStep(step === 3 ? 2 : 1)}
                            className="text-[20px] cursor-pointer"
                        />
                    </div>
                }
                <p className="flex justify-center text-[20px] font-semibold">
                    {step === 1 && "Signup and Start Advertising  for as low as 1 - Cent"}
                    {step === 2 && "One - Time - Passcode"}
                </p>
                <div className="flex justify-center mt-[1rem]">
                    <div className="w-[35rem] border border-[#000000] bg-[#ffffff] rounded-lg p-5">
                        {/* Signup form */}
                        {step === 1 &&
                            <form className="flex flex-col gap-y-3">
                                <div className="grid grid-cols-12 gap-3 items-center">
                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Name of your Business:
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="text"
                                            placeholder="Enter the name of your business"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>

                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Type of Business:
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="text"
                                            placeholder="Enter the type of business"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>

                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Location of Business:
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="text"
                                            placeholder="Enter the location of your business"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>

                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Revenue of Business :
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="text"
                                            placeholder="Enter the revenue of your business"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>

                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Email :
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="text"
                                            placeholder="Enter your email"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>

                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Confirm Email :
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="text"
                                            placeholder="Confirm your email"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>

                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Phone Number :
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="text"
                                            placeholder="Enter your phone number"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>

                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Password :
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="password"
                                            placeholder="Enter your password"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>

                                    <div className="col-span-5">
                                        <p className="text-[16px]">
                                            Confirm Password :
                                        </p>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="password"
                                            placeholder="Confirm your password"
                                            className="w-full px-3 py-2 border border-[#000000] rounded"
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-between items-center">
                                    <button
                                        onClick={() => navigate("/")}
                                        className="w-[10rem] py-2 text-[14px] bg-[#00AB07] text-[#ffffff] rounded"
                                    >
                                        Login
                                    </button>
                                    <button
                                        onClick={() => setStep(2)}
                                        className="w-[10rem] py-2 text-[14px] bg-[#000000] text-[#ffffff] rounded"
                                    >
                                        Continue to OTP
                                    </button>
                                </div>
                            </form>
                        }

                        {/* Enter OTP */}
                        {step === 2 &&
                            <form className="flex flex-col gap-y-3">
                                <p className="text-center px-10">
                                    A one - time - passcode has been sent to your email please check and confirm
                                </p>
                                <div className="flex items-center justify-center gap-x-6 w-full">
                                    {[1, 2, 3, 4, 5, 6].map((item) => (
                                        <input
                                            key={item}
                                            type="text"
                                            maxLength={1}
                                            className="w-[3rem] h-[3rem] text-center focus:outline-none bg-[#acacac] rounded"
                                        />
                                    ))}
                                </div>

                                <div className="flex justify-between items-center mt-2">
                                    <div className="text-[14px] text-[#00C2FF] cursor-pointer">
                                        Enter new email
                                    </div>
                                    <div className="text-[14px] text-[#640D5F]">
                                        01:00
                                    </div>
                                </div>

                                <div className="flex justify-between items-center mt-2">
                                    <div
                                        onClick={() => setStep(2)}
                                        className="w-[10rem] flex justify-center items-center cursor-pointer py-2 text-[14px] bg-[#000000] text-[#ffffff] rounded"
                                    >
                                        Resend OTP
                                    </div>
                                    <button
                                        onClick={() => setStep(3)}
                                        className="w-[10rem] py-2 text-[14px] bg-[#00AB07] text-[#ffffff] rounded"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </form>
                        }

                        {/* Thank you message */}
                        {step === 3 &&
                            <div className="flex flex-col gap-y-3">
                                <div className="flex items-center justify-center">
                                    <p className="w-[80%] text-[16px] text-center">
                                        Congratulations! You can now begin your advertising journey with Kind Viewer.
                                        If you want, you can choose to remain logged in or you can login again by clicking
                                        “Advertise With Us” on Kind Viewer and logging into your Advertising account.
                                    </p>
                                </div>
                                <div className="flex justify-between items-center mt-5">
                                    <button
                                        onClick={() => setStep(1)}
                                        className="w-[10rem] py-2 text-[14px] bg-[#000000] text-[#ffffff] rounded"
                                    >
                                        Sign out and Close
                                    </button>
                                    <button
                                        onClick={() => navigate("/")}
                                        className="w-[10rem] py-2 text-[14px] bg-[#00AB07] text-[#ffffff] rounded"
                                    >
                                        Remain Logged in
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp