import React, { Fragment, useState } from "react";
import step_3_preview from "../../../assets/createadvert/step_3_preview.png";

const StageOne = () => {
    const [keywords, setKeywords] = useState(["Chocolate", "Dessert", "Small Shop", "Cookies", "Vanilla"]);
    const [newKeyword, setNewKeyword] = useState("");

    const addKeyword = () => {
        if (newKeyword.trim() !== "") {
            setKeywords([...keywords, newKeyword.trim()]);
            setNewKeyword("");
        }
    };

    const removeKeyword = (index) => {
        setKeywords(keywords.filter((_, i) => i !== index));
    };

    return (
        <Fragment>
            {/* step 1 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-2">
                    <p className="text-[18px] font-bold">Step 1 : Keywords</p>
                    <p className="text-[12px] mt-2">Choose, Edit and Filter your Keywords :</p>
                </div>
                <div className="col-span-5">
                    <div className="flex gap-2 mb-2">
                        <input
                            type="text"
                            value={newKeyword}
                            onChange={(e) => setNewKeyword(e.target.value)}
                            placeholder="Type in your keyword..."
                            className="border w-full border-[#828282] p-2 rounded focus:outline-none"
                        />
                        <button
                            onClick={addKeyword}
                            className="bg-[#000000] text-white px-3 text-[14px] py-2 rounded"
                        >
                            Add
                        </button>
                    </div>
                    {keywords.length >= 1 &&
                        <div className="flex flex-wrap gap-2 border border-[#828282] p-2 rounded">
                            {keywords.map((keyword, index) => (
                                <div
                                    key={index}
                                    className="flex items-center bg-[#f0f0f0] px-2 py-1 h-auto rounded"
                                >
                                    <span className="mr-2">{keyword}</span>
                                    <button
                                        onClick={() => removeKeyword(index)}
                                        className="text-red-500"
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>

            {/* step 2 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-2">
                    <p className="text-[18px] font-bold">Step 2 : Add URL link</p>
                    <p className="text-[12px] mt-2">This is where people are taken after clicking your advert :</p>
                </div>
                <div className="col-span-5">
                    <input
                        type="text"
                        placeholder="Type in your URL..."
                        className="border w-full border-[#828282] p-2 mt-5 rounded focus:outline-none"
                    />
                </div>
            </div>

            {/* step 3 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-2">
                    <p className="text-[18px] font-bold">Step 3 : Choose Advert type</p>
                </div>
                <div className="col-span-12 flex gap-x-10">
                    <div className="flex flex-col gap-2 mt-5">
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="one" className="cursor-pointer accent-black" />
                            <label htmlFor="one">User Stream Ads</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="two" className="cursor-pointer accent-black" />
                            <label htmlFor="two">Kind View Ads</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="three" className="cursor-pointer accent-black" />
                            <label htmlFor="three">Kind View ads also</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="four" className="cursor-pointer accent-black" />
                            <label htmlFor="four">Kind Premium display Ads</label>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-5">
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="five" className="cursor-pointer accent-black" />
                            <label htmlFor="five">Short Fixed Ads</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="six" className="cursor-pointer accent-black" />
                            <label htmlFor="six">Kind Text Ads (Desktop Only)</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="seven" className="cursor-pointer accent-black" />
                            <label htmlFor="seven">Kind  display Ads</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input type="radio" name="advertType" id="eight" className="cursor-pointer accent-black" />
                            <label htmlFor="eight">Kind search - Feed Video Ads</label>
                        </div>
                    </div>
                    <div className="mt-5 ml-[6rem]">
                        <img src={step_3_preview} alt="step_3_preview" className="h-[7.3rem]" />
                    </div>
                </div>
            </div>

            {/* step 4 */}
            <div className="grid grid-cols-12 mt-5">
                <div className="col-span-2">
                    <p className="text-[18px] font-bold">Step 4 : Name of Business</p>
                    <p className="text-[12px] mt-2">Type the name of your business :</p>
                </div>
                <div className="col-span-5">
                    <input
                        type="text"
                        placeholder="Type in your business name..."
                        className="border w-full border-[#828282] p-2 mt-5 rounded focus:outline-none"
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default StageOne