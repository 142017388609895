import React, { useState } from "react";
import { HiBars3 } from "react-icons/hi2";
import { IoSearchSharp } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { FaHandPaper, FaUser } from "react-icons/fa";
import { BsFillQuestionDiamondFill } from "react-icons/bs";
import { FaCirclePlus, FaCaretDown } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    const [dropDownMenu, setDropDownMenu] = useState("Advertisers Business");
    const [showDropDownMenu, setShowDropDownMenu] = useState(false);

    const menuItems = [
        { id: 1, name: "Your Adverts", pathname: "/your-advert" },
        { id: 2, name: "1 - Cent Bidding", pathname: "/one-cent" },
        { id: 3, name: "Billing and Invoice", pathname: "" },
        { id: 4, name: "Edit Account", pathname: "" },
        { id: 5, name: "Trending Ads", pathname: "" },
        { id: 6, name: "Contact Us", pathname: "/contact-us" },
        { id: 7, name: "Contact Expert", pathname: "" },
        { id: 8, name: "Settings", pathname: "/settings" },
    ];

    return (
        <div className=" sticky top-0 flex items-center justify-between bg-[#D6EFD8] px-3 py-4 z-50 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
            <p
                onClick={() => navigate("/")}
                className="bg-[#00AB07] px-8 py-2 text-[#ffffff] text-[20px] font-semibold cursor-pointer rounded"
            >
                K.A.S
            </p>
            <div className="flex items-center gap-x-7">
                <div
                    onClick={() => navigate("/")}
                    className="flex items-center gap-x-2 px-5 py-2 bg-[#ffffff] rounded-full text-[14px] cursor-pointer hover:bg-[#000000] hover:text-[#ffffff] transition-all duration-300"
                >
                    {(location.pathname === "/" || location.pathname === "/signup") ? null :  <FaUser />}
                    
                    <p className="text-[14px]">
                        {(location.pathname === "/" || location.pathname === "/signup") ? "Login" : "Account"}
                    </p>
                </div>
                <div
                    onClick={() => navigate("/create-advert")}
                    className="flex items-center gap-x-2 px-5 py-2 bg-[#ffffff] cursor-pointer rounded hover:bg-[#000000] hover:text-[#ffffff] transition-all duration-300"
                >
                    <FaCirclePlus />
                    <p className="text-[14px]">
                        Create Adverts
                    </p>
                </div>
                <div
                    onClick={() => setShowDropDownMenu(!showDropDownMenu)}
                    className={`relative flex items-center gap-x-2 px-5 py-2 
                        ${showDropDownMenu ? "bg-[#000000] text-[#ffffff]" : "bg-[#ffffff]"} 
                        cursor-pointer rounded hover:bg-[#000000] hover:text-[#ffffff] transition-all duration-300`
                    }
                >
                    <p className="text-[14px]">
                        {dropDownMenu}
                    </p>
                    <FaCaretDown />

                    {showDropDownMenu &&
                        <div className="absolute top-11 left-0 w-full bg-black">
                            <p
                                onClick={() => setDropDownMenu("Menu One")}
                                className="text-[13px] text-white p-2 hover:bg-[#3d3c3c] border-b border-[#7d7b7b]"
                            >
                                Menu One
                            </p>
                            <p
                                onClick={() => setDropDownMenu("Menu Two")}
                                className="text-[13px] text-white p-2 hover:bg-[#3d3c3c] border-b border-[#7d7b7b]"
                            >
                                Menu Two
                            </p>
                            <p
                                onClick={() => setDropDownMenu("Menu Three")}
                                className="text-[13px] text-white p-2 hover:bg-[#3d3c3c]"
                            >
                                Menu Three
                            </p>
                        </div>
                    }
                </div>
                <div
                    className={`flex items-center gap-x-2 px-5 py-2 cursor-pointer rounded transition-all duration-300 
                        ${location.pathname === "/keywords" ?
                            "bg-[#000000] text-[#ffffff]" :
                            "bg-[#ffffff] hover:bg-[#000000] hover:text-[#ffffff]"
                        } 
                    `}
                >
                    <p onClick={() => navigate("/keywords")} className="text-[14px]">
                        Keywords
                    </p>
                    <IoSearchSharp />
                </div>
                <div
                    className={`flex items-center gap-x-2 px-5 py-2 cursor-pointer rounded transition-all duration-300 
                        ${location.pathname === "/how" ?
                            "bg-[#000000] text-[#ffffff]" :
                            "bg-[#ffffff] hover:bg-[#000000] hover:text-[#ffffff]"
                        } 
                    `}
                >
                    <p onClick={() => navigate("/how")} className="text-[14px]">
                        How It Works
                    </p>
                    <BsFillQuestionDiamondFill />
                </div>
                <div
                    onClick={() => navigate("/help-mode")}
                    className="flex items-center gap-x-2 px-5 py-2 bg-[#ffffff] cursor-pointer rounded hover:bg-[#000000] hover:text-[#ffffff] transition-all duration-300"
                >
                    <p className="text-[14px]">
                        Help Mode
                    </p>
                    <FaHandPaper />
                </div>
            </div>
            <div
                onClick={() => setShowMenu(!showMenu)}
                className={`p-[6px]  ${showMenu ? "bg-[#000000] text-[#ffffff]" : "bg-[#ffffff]"} cursor-pointer rounded hover:bg-[#000000] hover:text-[#ffffff] transition-all duration-300`}
            >
                {showMenu ?
                    <MdOutlineClose className="text-[25px]" /> :
                    <HiBars3 className="text-[25px]" />
                }
            </div>
            {showMenu &&
                <div className="absolute right-0 top-[5rem]">
                    <div className="px-6 pb-3 pt-6 bg-[#4F4949]">
                        {menuItems.map((item) => (
                            <div key={item.id} className="mb-3">
                                <p
                                    onClick={() => navigate(`${item.pathname}`)}
                                    className="w-[10rem] text-center py-2 text-[14px] bg-[#ffffff] hover:bg-[#000000] hover:text-[#ffffff] cursor-pointer"
                                >
                                    {item.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}

export default Header