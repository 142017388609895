import React, { Fragment, useState } from "react";
import support_img from "../assets/support.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaChevronDown, FaShare } from "react-icons/fa";
import { IoSearchSharp, IoClose } from "react-icons/io5";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const Support = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [selectedSupport, setSelectedSupport] = useState(null);

    const supportData = [
        {
            id: 1,
            title: "How can I check all my adverts?",
            description: "To check all your adverts, click the “Your Adverts” icon on the left side of your screen."
        },
        {
            id: 2,
            title: "How does bidding work?",
            description: "To check all your adverts, click the “Your Adverts” icon on the left side of your screen."
        },
        {
            id: 3,
            title: "How can I place a bid?",
            description: "To check all your adverts, click the “Your Adverts” icon on the left side of your screen."
        },
        {
            id: 4,
            title: "How does the Tier System work?",
            description: "To check all your adverts, click the “Your Adverts” icon on the left side of your screen."
        },
        {
            id: 5,
            title: "How can I change the data on the graph?",
            description: "To check all your adverts, click the “Your Adverts” icon on the left side of your screen."
        },
    ];

    return (
        <Fragment>
            {showMenu ?
                <div
                    onClick={() => setShowMenu(!showMenu)}
                    className="fixed bottom-2 right-2 bg-[#000000] h-[2.5rem] w-[2.5rem] flex flex-col items-center justify-center rounded-full cursor-pointer z-50"
                >
                    <FaChevronDown className="text-[#ffffff]" />
                </div>
                :
                <div
                    onClick={() => setShowMenu(!showMenu)}
                    className="fixed bottom-2 right-2 bg-[#000000] h-[4rem] w-[4rem] flex flex-col items-center justify-center rounded-full cursor-pointer z-50"
                >
                    <img
                        src={support_img}
                        alt="Support"
                        className="h-8 w-8"
                    />
                    <p className="text-[10px] font-bold text-[#ffffff] mt-[2px]">
                        Support
                    </p>
                </div>
            }
            {showMenu &&
                <div className="fixed bottom-14 right-2 w-[20rem] h-[25rem] rounded-lg p-3 bg-gradient-to-b from-[#37DB3D] to-[#640D5F] z-50">
                    <Fragment>
                        {selectedSupport == null &&
                            <p className="text-[22px] font-bold text-[#ffffff] leading-6">
                                Hey ! <br />
                                How we can help you ?
                            </p>
                        }
                        {selectedSupport !== null &&
                            <IoArrowBackCircleOutline
                                className="text-[30px] ml-[-3px] font-bold text-[#ffffff] cursor-pointer"
                                onClick={() => setSelectedSupport(null)}
                            />
                        }

                        <div className={`flex flex-col bg-white ${selectedSupport ? "h-[15rem]" : "h-[14rem]"} overflow-y-scroll mt-3 rounded relative`}>
                            {selectedSupport &&
                                <div className="p-2">
                                    <div className="h-[9.5rem] overflow-y-scroll">
                                        <p className="text-[18px] font-bold text-[#000000] leading-6">
                                            {selectedSupport.title}
                                        </p>
                                        <p className="text-[14px] text-[#000000] mt-2">
                                            {selectedSupport.description}
                                        </p>
                                    </div>
                                    <div className="absolute bottom-0 left-0 w-full flex items-center justify-center pt-1">
                                        <div className="flex flex-col items-center">
                                            <button className="py-[4px] px-3 text-[13px] rounded text-[#ffffff] bg-[#00AB07]">
                                                See more details
                                            </button>
                                            <p className="text-[10px] text-[#000000] mt-[6px]">
                                                Did this answer your question?
                                            </p>
                                            <div className="flex items-center gap-x-2">
                                                <p className="cursor-pointer">😊</p>
                                                <p className="cursor-pointer">😒</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {selectedSupport === null &&
                                <Fragment>
                                    <div className="sticky top-0">
                                        <div className="py-2 px-2 relative bg-white">
                                            <input
                                                type="text"
                                                placeholder="Search for help"
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}
                                                className="bg-[#dcdcdc] pl-2 pr-8 py-2 w-full text-[13px] rounded focus:outline-none"
                                            />
                                            <div className="absolute top-1/2 right-4 transform -translate-y-1/2">
                                                {inputValue.length > 1 ? (
                                                    <IoClose
                                                        className="text-[20px] text-[#000000] hover:text-[#f65d5d] cursor-pointer"
                                                        onClick={() => setInputValue("")}
                                                    />
                                                ) : (
                                                    <IoSearchSharp className="text-[20px] text-[#000000] cursor-pointer" />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {supportData.map((data) => (
                                        <div
                                            key={data.id}
                                            className="grid grid-cols-12 border-b p-[10px] border-[#cccccc] items-center cursor-pointer hover:bg-[#f6f6f6]"
                                            onClick={() => setSelectedSupport(data)}
                                        >
                                            <div className="col-span-11">
                                                <p className="text-[13px] text-[#000000]">
                                                    {data.title}
                                                </p>
                                            </div>
                                            <div className="col-span-1 flex items-center justify-end">
                                                <MdKeyboardArrowRight className="text-[#000000]" />
                                            </div>
                                        </div>
                                    ))}
                                </Fragment>
                            }
                        </div>

                        <textarea
                            rows={3}
                            placeholder="Type your message here"
                            className="text-[14px] w-full bg-white mt-[10px] resize-none rounded p-2 focus:outline-none"
                        />
                        <div className=" absolute bottom-5 right-5">
                            <FaShare className="cursor-pointer text-[#9e9e9e] hover:text-[#000000]" />
                        </div>
                    </Fragment>
                </div>
            }
        </Fragment>
    )
}

export default Support