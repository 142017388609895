import React from "react";
import { useNavigate } from "react-router-dom";
import your_tier from "../../assets/tiersystem/your_tier.png";
import cent_bidding from "../../assets/tiersystem/1_cent _bidding.png";
import higher_tier from "../../assets/tiersystem/team_up_with_higher_tier_advertisers.png";
import increase_application from "../../assets/tiersystem/tier_increase_application.png";
import how_tier_works from "../../assets/tiersystem/how_tier_system_works.png";

const TierSystemMenu = () => {
    const navigate = useNavigate();

    const menuItem = [
        { id: 1, name: "Your Tier", icon: your_tier, pathName: "/tier-system" },
        { id: 2, name: "1-Cent Bidding", icon: cent_bidding, pathName: "/one-cent" },
        { id: 3, name: "Team Up With Higher Tier Advertisers", icon: higher_tier, pathName: "/tier-system/higher-advertiser" },
        { id: 4, name: "Tier Increase Application", icon: increase_application, pathName: "/tier-system/increase-application" },
        { id: 5, name: "How Tier System Works", icon: how_tier_works, pathName: "/tier-system/how-it-work" },
        { id: 6, name: "Total users reached", number: "123000", pathName: "" }
    ];

    return (
        <div className="flex flex-col items-center mt-3">
            {menuItem.map((item) => (
                <div
                    key={item.id}
                    onClick={() => navigate(item.pathName)}
                    className="h-[6rem] w-[6rem] p-3 flex flex-col items-center justify-center rounded-full bg-[#ffffff] mb-3 cursor-pointer"
                >
                    {item.icon ? (
                        <img
                            src={item.icon}
                            alt={item.name}
                            className="h-[2.5rem] w-[2.5rem] mb-1"
                        />
                    ) : (
                        <p className="text-[18px] font-bold">{item.number}</p>
                    )}
                    <p className="text-[10px] text-[#000000] font-semibold leading-[12px] text-center">
                        {item.name}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default TierSystemMenu